import { SchemaOrg } from '/components/buildingBlocks/SchemaOrg'
import { employees } from '/components/aboutUs/assets/employees'
import { awards } from '/components/home/assets/awards'
import { useConfig } from '/machinery/useConfig'

export const organizationKaliber = {
  '@type': 'Organization',
  name: 'Kaliber',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Utrecht, The Netherlands',
    postalCode: '3513 DA',
    streetAddress: 'Zeedijk 19',
    email: 'hallo@kaliber.net',
    telephone: '00310302876400',
  },
  url: 'https://kaliber.net'
}

export function SchemaOrgOrganizationKaliber() {
  const { origin } = useConfig()
  return (
    <SchemaOrg obj={{
      '@context' : 'http://schema.org',
      alternateName: 'Kaliber Interactive',
      description: 'Wij zijn Kaliber, een digitaal bureau dat strategie, creativiteit en technologie combineert om mensen te activeren. We weten wat er kan en vooral wat er werkt. Het resultaat is intensieve interactie waardoor we mensen en merken een beetje verliefd op elkaar laten worden.',
      sameAs: [
        'https://www.facebook.com/kaliberinteractive/',
        'https://www.instagram.com/kaliberinteractive/',
        'https://bsky.app/profile/kaliber.net/',
        'https://www.linkedin.com/company/kaliber-interactive/',
        'https://github.com/kaliberjs'
      ],
      awards: Object.keys(awards),
      employees: employees.filter(x => x.isEmployee).map(({ name, functionTitle, emailAddress }) => ({
        '@type': 'Person',
        name,
        email: emailAddress,
        jobTitle: functionTitle
      })),
      logo: `${origin}/static/logo.png`,
      ...organizationKaliber
    }} />
  )
}
